<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/cards/7-1400.jpg')" title="RPC TO RPL CONVERSION" subtitle="Jump in" />
    <div class="program-description">
      <h1>What is an RPC to RPL conversion?</h1>
      <p>To convert to a RPL you need to complete the CASA application form and follow the instructions on the form to provide evidence of your pilot certificate, submit a recent photograph and provide appropriate proof of your identity. You also need to undertake a flight review before you can use your licence.</p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
